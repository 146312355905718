<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("services") }}</h2>
      <p>{{ $t("services-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="7">
        <datatable :headers="headers" :table="entries">
          <template #[`item.name`]="{ item }">
            <span>{{ item.name }}</span>
          </template>
          <template #[`item.active`]="{ item }">
            <span>{{ $t(item.active ? "yes" : "no") }}</span>
          </template>
          <template #[`item.limit_day`]="{ item }">
            <span>{{ `${$t("daily")}: ${$options.filters.money(item.limit_day)}` }}</span>
            <span>{{
              `${$t("monthly")}: ${$options.filters.money(item.limit_month)}`
            }}</span>
          </template>
          <template #[`item.id`]="{ item }">
            <div class="svg-buttons" style="white-space: nowrap">
              <v-btn
                small
                icon
                color="success"
                class="pl-0 pr-0"
                @click.prevent="edit(item)"
              >
                <v-img max-width="24px" :src="require(`@/assets/edit.svg`)" />
              </v-btn>
            </div>
          </template>
        </datatable>
      </v-col>
      <v-col cols="12" md="5">
        <validation-observer v-slot="{ valid }">
          <v-form :disabled="!request.id" @submit.prevent="submit">
            <v-row no-gutters>
              <v-col>
                <h2 :class="`mb-2 ${!request.id ? 'fg-gray' : ''}`">
                  {{ $t(`services-.edit`) }}
                </h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field v-model="request.name" outlined disabled :label="$t('name')" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mr-2">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('monthly-limit').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.limit_month"
                    outlined
                    :label="$t('monthly-limit')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('daily-limit').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.limit_day"
                    outlined
                    :label="$t('daily-limit')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox v-model="request.active" :label="$t('active')" class="mt-0" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6">
                <v-btn
                  block
                  outlined
                  color="error"
                  elevation="0"
                  :disabled="!request.id"
                  @click.prevent="resetForm"
                >
                  {{ $t("discard") }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  :disabled="!valid || !request.id"
                  elevation="0"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import services from "../requests/services";

export default {
  mixins: [],
  components: {
    Heading,
    Datatable,
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("limits"),
          value: "limit_day",
          sortable: false,
        },
        {
          text: this.$t("active"),
          value: "active",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    requestBase: {
      id: "",
      limit_day: "",
      limit_month: "",
      active: false,
    },
    request: null,
  }),
  watch: {},
  created: async function () {
    await this.resetForm();
    this.loadEntries();
  },
  methods: {
    loadEntries: async function () {
      const response = await services.load();
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
    },
    submit: async function () {
      try {
        await services.update(this.request.id, this.request);
        this.resetForm();
        this.loadEntries();
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    resetForm: async function () {
      this.request = Object.assign({}, this.requestBase);
    },
    edit: async function (item) {
      this.request = Object.assign({}, item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
