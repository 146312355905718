<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("remittances-.details") }}</h2>
      <p>{{ $t("remittances-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col class="px-6">
        <v-row v-if="remittance">
          <v-col cols="12" class="px-0">
            <remittance-details :remittance="remittance" @deleted="deleted" />
          </v-col>
          <v-col
            v-if="remittance.account && isAdmin(getUserInfo.level)"
            cols="12"
            sm="4"
            md="3"
          >
            <delete :item="remittance" @deleted="deleted" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-skeleton-loader type="article" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import RemittanceDetails from "./Remittances/Details";
import Delete from "./Remittances/Delete";
import usersMixin from "../mixins/users";
import remittances from "../requests/remittances";
import { mapGetters } from "vuex";

export default {
  mixins: [usersMixin],
  components: {
    Heading,
    Delete,
    RemittanceDetails,
  },
  props: {
    remittanceId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    hideDialog: true,
    remittance: null,
  }),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  created: async function () {
    this.remittance = await remittances.details(this.$route.params.id);
  },
  methods: {
    deleted: async function () {
      this.hideDialog = true;
      this.$router.push({ name: "remittances" });
    },
  },
};
</script>
