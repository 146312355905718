<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        {{ statusIcon }}
      </v-icon>
    </template>
    <span>{{ $t(status) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  mixins: [],
  props: {
    status: {
      type: String,
      default: "pending",
    },
  },
  components: {},
  computed: {
    statusIcon: function () {
      if (this.status == "cancelled") return "mdi-cancel";
      return this.status === "pending" ? "mdi-clock-outline" : "mdi-check";
    },
  },
  data: () => ({}),
  watch: {},
  created: async function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
