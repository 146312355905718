<template>
  <confirm-dialog hide-actions @shown="shown">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small icon color="success" class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-icon>mdi-cash-fast</v-icon>
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("remittances-.process") }}
    </template>
    <validation-observer ref="observer" v-slot="{ valid }">
      <v-form @submit.prevent="submit" class="mt-2">
        <v-row no-gutters>
          <v-col>
            <validation-provider v-slot="{ errors }" :name="$t('account').toLowerCase()" :rules="{ required: true }">
              <v-select v-model="request.account" outlined item-value="id" item-text="card_number" :items="accounts"
                :label="$t('account')" :error-messages="errors">
                <template #selection="{ item }">
                  {{ `${item.name} - ${$t(item.currency)}` }}
                </template>
                <template #item="{ item }">
                  {{ `${item.name} - ${$t(item.currency)}: ${item.card_number}` }}
                </template>
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('amount-received').toLowerCase()"
              :rules="{ required: true, double: true }"
            >
              <v-text-field
                v-model="request.received_amount"
                outlined
                :label="$t('amount-received')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row> -->
        <v-row class="mt-0">
          <v-col>
            <v-btn block color="primary" type="submit" :disabled="!valid" elevation="0">
              {{ $t("submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import remittances from "../../requests/remittances";
import accounts from "../../requests/accounts";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    requestBase: {
      account: null,
      received_amount: "",
    },
    request: null,
    accounts: [],
  }),
  created: async function () {
    this.resetForm();
  },
  methods: {
    shown: async function () {
      this.accounts = this.accounts.length == 0 ? await accounts.list({ allow_deposits: 1 }) : this.accounts;
      if (this.accounts.length > 0) {
        this.request.account = this.accounts[0].id;
      }
      this.request.received_amount = this.item.received_amount;
    },
    submit: async function () {
      try {
        await remittances.process(this.item.id, this.request);
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
        this.resetForm();
        this.$emit("processed");
      } catch (e) {
        if (e.response.status == 402) {
          await this.$store.dispatch("notificator/error", this.$t("remittances-.not-available-credit"));
        } else {
          await this.$store.dispatch("notificator/errorResponse", e);
        }
      }
    },
    resetForm: async function () {
      this.request = Object.assign({}, this.requestBase);
      if (this.$refs.observer) this.$refs.observer.reset();
    },
  },
};
</script>
