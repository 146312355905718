<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("movements") }}</h2>
      <p>{{ $t("movements-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";

export default {
  components: {
    Heading,
  },
  computed: {},
  data: () => ({}),
  watch: {},
  created: async function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
