<template>
  <v-container v-if="user" fluid class="py-0">
    <heading>
      <h2>{{ $t("users-.details") }}</h2>
      <p>{{ $t("users-.details-sub-heading") }}</p>
      <template #actions>
        <v-btn
          color="primary"
          elevation="0"
          @click.prevent="$router.push({ name: 'users-new' })"
        >
          <v-img max-width="22px" :src="require(`@/assets/plus.svg`)" class="mr-2" />
          {{ $t("users-.new") }}
        </v-btn>
      </template>
    </heading>
    <split-content>
      <template #auxiliar>
        <search-users @selected="selectedItem = $event" />
      </template>
      <v-container fluid>
        <v-row>
          <v-col>
            <h4 class="fg-black">{{ $t(`level-.${user.level}`) }}</h4>
          </v-col>
          <v-col cols="6" class="svg-buttons small-buttons text-right">
            <v-btn
              small
              text
              class="fg-blue"
              @click="$router.push({ name: 'users-edit', params: { id: user.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
              {{ $t("edit") }}
            </v-btn>
            <remove-dialog :item="user" @removed="$router.push({ name: 'users' })">
              <template #activator="{ on, attrs }">
                <v-btn small text class="fg-red" v-bind="attrs" v-on="on">
                  <v-img max-width="22px" :src="require(`@/assets/delete-hover.svg`)" />
                  {{ $t("remove") }}
                </v-btn>
              </template>
            </remove-dialog>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("name") }}</template>
              {{ user.name }}
            </attribute>
          </v-col>
          <v-col v-if="user.business" cols="6">
            <attribute>
              <template #name>{{ $t("business") }}</template>
              {{ user.business.name }}
            </attribute>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4 class="fg-black">{{ $t("account") }}</h4>
          </v-col>
          <v-col cols="6" class="svg-buttons small-buttons text-right">
            <v-btn
              small
              text
              class="fg-blue"
              @click="$router.push({ name: 'users-edit', params: { id: user.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
              {{ $t("edit") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="12" sm="4">
            <attribute>
              <template #name>{{ $t("email") }}</template>
              {{ user.email }}
            </attribute>
          </v-col>
          <v-col cols="12" sm="4">
            <attribute>
              <template #name>{{ $t("password") }}</template>
              <change-password-dialog :item="user">
                <template #activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on">{{ $t("change-password") }}</a>
                </template>
              </change-password-dialog>
            </attribute>
          </v-col>
          <v-col cols="12" sm="4">
            <attribute>
              <template #name>{{ $t("active") }}</template>
              {{ $t(user.active ? "yes" : "no") }}
            </attribute>
          </v-col>
        </v-row>
        <div v-if="isBusinessDependant(user.level)">
          <v-row>
            <v-col>
              <h4 class="fg-black">{{ $t("business") }}</h4>
            </v-col>
          </v-row>
          <v-row class="bottom-lined">
            <v-col cols="12">
              <attribute>
                {{ user.business.name }}
              </attribute>
            </v-col>
          </v-row>
        </div>
        <div v-if="isReseller(user.level)">
          <v-row>
            <v-col>
              <h4 class="fg-black">{{ $t("services") }}</h4>
            </v-col>
            <v-col class="text-right">
              <reseller-services-dialog
                v-if="isBusinessDependant(user.level)"
                :item="user"
                @success="reloadServices"
              >
                <template #activator="{ on, attrs }">
                  <v-btn small text class="fg-blue" v-bind="attrs" v-on="on">
                    <v-img max-width="22px" :src="require(`@/assets/category.svg`)" />
                    {{ $t("edit") }}
                  </v-btn>
                </template>
              </reseller-services-dialog>
            </v-col>
          </v-row>
          <v-row class="bottom-lined">
            <v-col
              v-for="userService of services"
              :key="userService.service.id"
              cols="12"
            >
              <v-row>
                <v-col>
                  <attribute>
                    <template #name>{{ $t("service") }}</template>
                    {{ userService.service.name }}
                  </attribute>
                </v-col>
                <v-col>
                  <attribute>
                    <template #name>{{ $t("fee") }}</template>
                    {{ userService.fee }}
                  </attribute>
                </v-col>
                <v-col>
                  <attribute>
                    <template #name>{{ $t("profit-margin") }}</template>
                    {{ userService.margin }}
                  </attribute>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </split-content>
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import Attribute from "../components/Attribute";
import SplitContent from "../components/SplitView";
import users from "../../requests/users";
import RemoveDialog from "./RemoveDialog";
import SearchUsers from "./Search";
import ChangePasswordDialog from "../components/ChangePasswordDialog";
import ResellerServicesDialog from "./ResellerServicesDialog";
import usersMixin from "../../mixins/users";
import { mapGetters } from "vuex";

export default {
  mixins: [usersMixin],
  components: {
    Heading,
    Attribute,
    SplitContent,
    RemoveDialog,
    ChangePasswordDialog,
    ResellerServicesDialog,
    SearchUsers,
  },
  data: () => ({
    user: null,
    services: [],
    timeout: null,
    search: "",
    selectedItem: null,
  }),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  watch: {
    selectedItem: async function (value) {
      if (value == null) {
        return;
      }
      this.user = await users.details(value.id);
    },
  },
  created: async function () {
    if (!this.$route.params.id) {
      this.$router.push({ name: "users" });
    }
    if (this.selectedItem == null) {
      this.user = await users.details(this.$route.params.id);
      this.reloadServices();
    }
  },
  methods: {
    reloadServices: async function () {
      this.services = await users.services(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
