import api from '@/plugins/api'

export const users = {
  load: async function (filters) {
    try {
      const response = await api.get('/users', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  resellers: async function () {
    try {
      const response = await api.get('/users/resellers')
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  details: async function (id) {
    try {
      const response = await api.get(`/users/${id}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function (data) {
    try {
      const response = await api.post('/users', data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  update: async function (id, data) {
    try {
      const response = await api.patch(`/users/${id}`, data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  remove: async function (id) {
    try {
      await api.delete(`/users/${id}`)
      return true
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  changeOtherPassword: async function (id, password, confirm) {
    try {
      const response = await api.put(`/users/${id}/password`, { password: password, password_confirmation: confirm })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  services: async function (id) {
    try {
      const response = await api.get(`/users/${id}/services`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  serviceBySlug: async function (id, slug) {
    try {
      const response = await api.get(`/users/${id}/services/${slug}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  updateServices: async function (id, slug, fee, margin) {
    try {
      const response = await api.put(`/users/${id}/services`, {
        slug: slug,
        fee: fee,
        margin: margin,
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default users