<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("remittances") }}</h2>
      <p>{{ $t("remittances-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="6" lg="7" xl="9">
        <v-row>
          <v-col cols="12" class="pt-0">
            <datatable :headers="headers" :table="entries">
              <template v-slot:top>
                <filters-modal @filtered="loadEntries" />
              </template>
              <template #[`item.paid_amount`]="{ item }">
                <span>{{
                  `${$t(item.sale.payment_method)}: ${
                    item.sale.payment_sender
                      ? item.sale.payment_sender
                      : item.sale.payment_code
                  }`
                }}</span>
                <span :class="item.sale.status === 'cancelled' ? 'strickeout' : ''">
                  {{
                    `${$t(item.sale.paid_currency)}: \$${$options.filters.money(
                      item.sale.paid_amount
                    )}`
                  }}
                </span>
              </template>
              <template #[`item.received_amount`]="{ item }">
                <span>{{ item.account_number | cardNumber }}</span>
                <span :class="item.sale.status === 'cancelled' ? 'strickeout' : ''">
                  {{
                    `${$t(item.received_currency)}: \$${$options.filters.money(
                      item.received_amount
                    )}`
                  }}
                </span>
                <span>{{ item.note }}</span>
              </template>
              <template #[`item.created_at`]="{ item }">
                <span>{{ date(item.created_at) }}</span>
                <span
                  v-if="!isBusinessDependant(getUserInfo.level) && item.sale.reseller"
                >
                  {{ item.sale.reseller.name }}
                </span>
              </template>
              <template #[`item.status`]="{ item }">
                <status-icon :status="item.sale.status" />
              </template>
              <template #[`item.id`]="{ item }">
                <div class="svg-buttons" style="white-space: nowrap">
                  <remittance-details :remittance-id="item.id" @reload="loadEntries" />
                  <process
                    v-if="
                      !isBusinessDependant(getUserInfo.level) &&
                      item.sale.status === 'pending'
                    "
                    :item="item"
                    @processed="loadEntries"
                  />
                  <cancel
                    v-if="
                      !isBusinessDependant(getUserInfo.level) &&
                      item.sale.status === 'pending'
                    "
                    :item="item.sale"
                    @cancelled="loadEntries"
                  />
                  <reasign
                    v-if="
                      !isBusinessDependant(getUserInfo.level) &&
                      item.sale.status === 'confirmed' &&
                      !item.sale.reseller
                    "
                    :item="item"
                    @reasigned="loadEntries"
                  />
                </div>
              </template>
            </datatable>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <remittance-form @success="loadEntries" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import StatusIcon from "./components/StatusIcon";
import RemittanceForm from "./Remittances/Send";
import Cancel from "./Remittances/Cancel";
import Process from "./Remittances/Process";
import Reasign from "./Remittances/Reasign";
import remittances from "../requests/remittances";
import RemittanceDetails from "./Remittances/DetailsModal";
import FiltersModal from "./Remittances/FiltersModal";
import sales from "../requests/sales";
import usersMixin from "../mixins/users";
import * as dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  mixins: [usersMixin],
  components: {
    Heading,
    Datatable,
    RemittanceForm,
    StatusIcon,
    Cancel,
    Process,
    Reasign,
    RemittanceDetails,
    FiltersModal,
  },
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
    headers: function () {
      return [
        {
          text: this.$t("payment"),
          value: "paid_amount",
          sortable: false,
        },
        {
          text: this.$t("receives"),
          value: "received_amount",
          sortable: false,
        },
        {
          text: this.$t("date"),
          value: "created_at",
          sortable: false,
        },
        {
          text: "",
          value: "status",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
  }),
  watch: {
    "entries.options": function () {
      this.loadEntries();
    },
  },
  created: async function () {
    // await this.loadEntries();
  },
  methods: {
    date: function (date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    loadEntries: async function (filters) {
      filters = filters ?? {};
      filters.start = (this.entries.options.page - 1) * this.entries.options.itemsPerPage;
      filters.length = this.entries.options.itemsPerPage;
      const response = await remittances.load(filters);
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
    },
    cancel: async function (item) {
      if (await sales.cancel(item.sale.id)) {
        await this.loadEntries();
      }
    },
    approve: async function (item) {
      if (await remittances.approve(item.id)) {
        await this.loadEntries();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table {
  position: relative;
}
</style>
