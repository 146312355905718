var users = {
  methods: {
    isAdmin: function (userLevel) {
      return userLevel == 'admin';
    },
    isAccountant: function (userLevel) {
      return userLevel == 'accountant';
    },
    isReseller: function (userLevel) {
      return userLevel == 'reseller';
    },
    isBusinessDependant: function (userLevel) {
      return ['manager', 'provider', 'reseller'].includes(userLevel);
    },
  }
}
export default users;