<template>
  <confirm-dialog hide-actions :show="hideDialog" @shown="shown">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn
          small
          icon
          color="primary"
          class="ml-2 pl-0 pr-0 filter-button"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("filters") }}
    </template>

    <v-row>
      <v-col cols="12" sm="12">
        <v-text-field
          v-model="filters.keyword"
          :label="$t('account-number')"
          outlined
          clearable
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.from"
              :label="$t('date-from')"
              readonly
              outlined
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
            >
              <template #prepend-inner>
                <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.from"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.to"
              :label="$t('date-to')"
              readonly
              outlined
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
            >
              <template #prepend-inner>
                <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.to"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="!isReseller(getUserInfo.level)">
      <v-col>
        <v-select
          v-model="filters.reseller"
          outlined
          item-value="id"
          item-text="name"
          :items="resellers"
          return-object
          hide-details
          :label="$t('reseller')"
        >
          <template #selection="{ item }">
            {{ item.id ? `${item.name} - ${item.email}` : $t(item.name) }}
          </template>
          <template #item="{ item }">
            {{ item.id ? `${item.name} - ${item.email}` : $t(item.name) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="filters.status"
          outlined
          :items="statuses"
          return-object
          hide-details
          :label="$t('status')"
        >
          <template #selection="{ item }">
            {{ $t(item) }}
          </template>
          <template #item="{ item }">
            {{ $t(item) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn block elevation="0" small @click.prevent="hideDialog = true">
          {{ $t("discard") }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn block color="primary" elevation="0" small @click.prevent="apply">
          {{ $t("apply") }}
        </v-btn>
      </v-col>
    </v-row>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import userMixin from "../../mixins/users";
import users from "../../requests/users";
import * as dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  mixins: [userMixin],
  components: {
    ConfirmDialog,
  },
  props: {},
  data: () => ({
    filters: {
      keyword: "",
      from: "",
      to: "",
      reseller: "",
      status: "all",
    },
    resellers: [{ id: "", name: "all", email: "" }],
    statuses: ["all", "pending", "cancelled", "confirmed"],
    hideDialog: false,
  }),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  created: async function () {
    this.resellers = this.resellers.concat(await users.resellers());
  },
  methods: {
    shown: async function () {
      this.hideDialog = false;
    },
    apply: async function () {
      this.hideDialog = true;
      const filters = {};
      if (this.filters.keyword) {
        filters.keyword = this.filters.keyword;
      }
      if (this.filters.reseller.id) {
        filters.reseller = this.filters.reseller.id;
      }
      if (this.filters.from) {
        filters.from = dayjs(this.filters.from).format("YYYY-MM-DDTHH:mm:ssZ");
      }
      if (this.filters.to) {
        filters.to = dayjs(this.filters.to).endOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
      }
      if (this.filters.status !== "all") {
        filters.status = this.filters.status;
      }
      this.$emit("filtered", filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-button {
  position: absolute;
  right: 10px;
}
</style>
