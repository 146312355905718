import api from '@/plugins/api'

export const users = {
  load: async function (filters) {
    try {
      const response = await api.get('/deposits', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  details: async function (id) {
    try {
      const response = await api.get(`/deposits/${id}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function (data) {
    try {
      const response = await api.post('/deposits', data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  update: async function (id, data) {
    try {
      const response = await api.patch(`/deposits/${id}`, data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  remove: async function (id) {
    try {
      await api.delete(`/deposits/${id}`)
      return true
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  approve: async function (id) {
    try {
      await api.put(`/deposits/${id}`)
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default users
