import api from '@/plugins/api'

export const users = {
  cancel: async function (id) {
    try {
      await api.delete(`/sales/${id}`)
      return true
    } catch (e) {
      console.error(e);
    }
    return false;
  },
}

export default users
