<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("profits") }}</h2>
      <p>{{ $t("profits-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12">
        <split-content class="mt-3">
          <template #auxiliar>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-menu transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.from"
                      :label="$t('date-from')"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template #prepend-inner>
                        <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="filters.from"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-menu transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.to"
                      :label="$t('date-to')"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template #prepend-inner>
                        <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="filters.to"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="!isReseller(getUserInfo.level)">
              <v-col class="mb-0">
                <v-select
                  v-model="filters.reseller"
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="resellers"
                  return-object
                  :label="$t('reseller')"
                >
                  <template #selection="{ item }">
                    {{ item.id ? `${item.name} - ${item.email}` : $t(item.name) }}
                  </template>
                  <template #item="{ item }">
                    {{ item.id ? `${item.name} - ${item.email}` : $t(item.name) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-0">
              <v-col>
                <v-select
                  v-model="filters.currency"
                  outlined
                  :items="['all', 'mlc', 'usd']"
                  :label="$t('currency')"
                >
                  <template #selection="{ item }">
                    {{ $t(item) }}
                  </template>
                  <template #item="{ item }">
                    {{ $t(item) }}
                  </template>
                </v-select>
              </v-col>
            </v-row> -->
          </template>
          <v-row no-gutters>
            <v-col>
              <datatable :headers="headers" :table="entries">
                <template #[`item.currency`]="{ item }">
                  <span>{{ $t(item.currency) }}</span>
                </template>
                <template #[`item.user`]="{ item }">
                  <span>{{ item.user ? item.user.name : "-" }}</span>
                  <span>{{ `${$t("approved-by")}: ${item.approver.name}` }}</span>
                </template>
                <template #[`item.created_at`]="{ item }">
                  <span>{{ date(item.created_at) }}</span>
                </template>
                <template #[`item.amount`]="{ item }">
                  <span>{{ `\$${$options.filters.money(item.amount)}` }}</span>
                </template>
                <template #[`item.id`]="{ item }">
                  <remittance-details :remittance-id="item.sale.remittance.id" />
                </template>
                <template #[`body.append`]>
                  <tr v-for="entry in totals" :key="entry.currency">
                    <td colspan="2">
                      {{
                        `Total ${$t(entry.currency)}: \$${$options.filters.money(
                          entry.total
                        )}`
                      }}
                    </td>
                    <td colspan="2">
                      {{
                        fee > 0
                          ? `Total MLC: \$${$options.filters.money(entry.total / fee)}`
                          : ""
                      }}
                    </td>
                  </tr>
                </template>
              </datatable>
            </v-col>
          </v-row>
        </split-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Heading from "./components/Heading";
import SplitContent from "./components/SplitView";
import Datatable from "./components/Datatable";
import profits from "../requests/profits";
import * as dayjs from "dayjs";
import users from "../requests/users";
import userMixin from "../mixins/users";
import RemittanceDetails from "./Remittances/Details";

const MLC_SERVICE = "mlc-remittance";

export default {
  mixins: [userMixin],
  components: {
    Heading,
    Datatable,
    SplitContent,
    RemittanceDetails,
  },
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
    formAction: function () {
      return this.request.id === "" ? "new" : "edit";
    },
    headers: function () {
      return [
        {
          text: this.$t("currency"),
          align: "start",
          value: "currency",
          sortable: true,
        },
        {
          text: this.$t("amount"),
          align: "start",
          value: "amount",
          sortable: true,
        },
        {
          text: this.$t("reseller"),
          align: "start",
          value: "user",
          sortable: true,
        },
        {
          text: this.$t("date"),
          value: "created_at",
          sortable: true,
        },
        {
          text: "",
          value: "id",
          sortable: true,
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    totals: [],
    resellers: [
      { id: "", name: "all", email: "" },
      { id: null, name: "company", email: "" },
    ],
    fee: 0,
    filters: {
      // currency: "all",
      reseller: null,
      from: "",
      to: "",
    },
  }),
  watch: {
    "entries.options": function () {
      this.loadEntries();
    },
    "filters.reseller": async function (reseller) {
      this.fee = 0;
      if (reseller.id) {
        const service = await users.serviceBySlug(reseller.id, MLC_SERVICE);
        this.fee = service.fee;
      }
    },
    filters: {
      handler: function () {
        this.loadEntries();
      },
      deep: true,
    },
  },
  created: async function () {
    this.resellers = this.resellers.concat(await users.resellers());
    this.filters.reseller = this.resellers[0];
    this.filters.from = dayjs().startOf("month").format('YYYY-MM-DD')
  },
  methods: {
    loadEntries: async function () {
      const params = {
        start: (this.entries.options.page - 1) * this.entries.options.itemsPerPage,
        length: this.entries.options.itemsPerPage,
      };
      // if (this.filters.currency != "all") {
      //   params.currency = this.filters.currency;
      // }
      if (this.filters.from) {
        params.from = this.filters.from;
      }
      if (this.filters.to) {
        params.to = this.filters.to;
      }
      if (this.filters.from) {
        params.from = dayjs(this.filters.from).format("YYYY-MM-DDTHH:mm:ssZ");
      }
      if (this.filters.to) {
        params.to = dayjs(this.filters.to).endOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
      }
      if (this.filters.reseller && this.filters.reseller.id != "") {
        params.reseller = this.filters.reseller.id;
      }
      const response = await profits.load(params);
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
      this.totals = response.totals;
    },
    date: function (date) {
      return dayjs(date).format("DD/MM/YYYY hh:mm A");
    },
  },
};
</script>

<style lang="scss" scoped></style>
