<template>
  <validation-observer ref="observer" v-slot="{ valid }">
    <v-form @submit.prevent="submit">
      <v-row no-gutters>
        <v-col>
          <h2 class="mb-2">{{ $t("remittances-.send") }}</h2>
        </v-col>
      </v-row>
      <v-row v-if="!asReseller" no-gutters>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('reseller').toLowerCase()"
            :rules="{ required: true }"
          >
            <v-select
              v-model="reseller"
              outlined
              :items="resellers"
              item-value="id"
              :label="$t('reseller')"
              :error-messages="errors"
              return-object
            >
              <template #selection="{ item }">
                {{ item.name }}
              </template>
              <template #item="{ item }">
                {{ item.name }}
              </template>
            </v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('account-number').toLowerCase()"
            :rules="{ /*required: true,*/ length: 16 }"
          >
            <v-text-field
              v-model="request.account_number"
              outlined
              :label="$t('account-number')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="mr-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('payment-method').toLowerCase()"
            :rules="{ required: true }"
          >
            <v-select
              v-model="request.payment_method"
              outlined
              :items="['paypal', 'zelle']"
              :label="$t('payment-method')"
              :error-messages="errors"
            >
              <template #selection="{ item }">
                {{ $t(item) }}
              </template>
              <template #item="{ item }">
                {{ $t(item) }}
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('sender').toLowerCase()"
            :rules="{ required: true, min: 4 }"
          >
            <v-text-field
              v-model="request.payment_sender"
              outlined
              :label="$t('sender')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <!-- <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('confirmation_no').toLowerCase()"
            :rules="{ required: true, min: 4 }"
          >
            <v-text-field
              v-model="request.payment_code"
              outlined
              :label="$t('confirmation_no')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col> -->
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" class="mr-2">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('amount-paid').toLowerCase()"
            :rules="{ required: true, double: true }"
          >
            <v-text-field
              v-model="request.paid_amount"
              outlined
              :label="$t('amount-paid')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fee').toLowerCase()"
            :rules="{ required: true, double: true }"
          >
            <v-select
              v-model="request.margin"
              outlined
              :items="margins"
              :label="$t('fee')"
              :error-messages="errors"
            >
              <template #selection="{ item }">
                {{ item.toFixed(2) }}
              </template>
              <template #item="{ item }">
                {{ item.toFixed(2) }}
              </template>
            </v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" class="mr-2">
          {{ $t("receives") }}: {{ request.received_amount }}
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col v-if="request.id" cols="6">
          <v-btn block outlined color="error" elevation="0" @click.prevent="resetForm">
            {{ $t("discard") }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block color="primary" type="submit" :disabled="!valid" elevation="0">
            {{ $t("submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import remittances from "../../requests/remittances";
import users from "../../requests/users";
import usersMixin from "../../mixins/users";

export default {
  mixins: [usersMixin],
  components: {},
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  data: () => ({
    asReseller: true,
    resellers: [],
    margins: [],
    reseller: null,
    requestBase: {
      reseller: "",
      currency: "mlc",
      account_number: "",
      paid_amount: "",
      margin: 1.1,
      received_amount: "",
      paid_currency: "usd",
      payment_method: "zelle",
      payment_sender: "",
      // payment_code: "",
    },
    request: null,
  }),
  watch: {
    request: {
      handler: function () {
        this.request.received_amount =
          this.request.margin > 0
            ? (this.request.paid_amount / this.request.margin).toFixed(2)
            : 0;
      },
      deep: true,
    },
    reseller: function () {
      if (this.reseller) {
        this.request.reseller = this.reseller.id;
        let i = parseFloat(this.reseller.service.fee);
        this.margins = [];
        while (i <= parseFloat(this.reseller.service.margin) + 0.01) {
          this.margins.push(i);
          i += 0.01;
        }
        this.request.margin = this.margins[this.margins.length - 1];
      }
    },
  },
  created: async function () {
    this.resetForm();
    this.asReseller = this.isReseller((await this.getUserInfo).level);
    this.resellers = await users.resellers();
    this.reseller = this.resellers[0];
  },
  methods: {
    submit: async function () {
      try {
        await remittances.create(this.request);
        await this.resetForm();
        await this.$emit("success");
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
        console.error(e);
      }
    },
    resetForm: async function () {
      this.request = Object.assign({}, this.requestBase);
      this.request.reseller = this.reseller ? this.reseller.id : "";
      this.request.margin =
        this.margins.length > 0 ? this.margins[this.margins.length - 1] : "";
      if (this.$refs.observer) this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss" scoped></style>
