import Vue from 'vue'

Vue.filter('cardNumber', (value) => {
  try {
    return value.replace(/(.{4})/g, "$1-").replace(/-$/, "")
  } catch (error) {
    console.error(`Error formatting ${value} to card number.`)
    return ''
  }
})
