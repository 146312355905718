import api from '@/plugins/api';

export const reports = {
  sales: {
    resume: async function (filters) {
      try {
        const response = await api.get('/reports/sales/resume', {
          params: filters
        })
        return response.data
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
}

export default reports
