<template>
  <confirm-dialog hide-actions :show="hideDialog" @shown="shown">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small icon color="primary" class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-icon>mdi-point-of-sale</v-icon>
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("remittances-.details") }}
    </template>

    <v-row v-if="remittance">
      <v-col cols="12" class="px-0">
        <remittance-details :remittance="remittance" @deleted="deleted" />
      </v-col>
      <v-col v-if="isAdmin(getUserInfo.level)" cols="6">
        <delete :item="remittance" @deleted="deleted" />
      </v-col>
      <v-col cols="6">
        <v-btn
          block
          elevation="0"
          small
          @click.prevent="
            $router.push({ name: 'remittance-details', params: { id: remittance.id } })
          "
        >
          <v-icon class="mr-2">mdi-sale-outline</v-icon>
          {{ $t("remittances-.view") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import RemittanceDetails from "./Details";
import usersMixin from "../../mixins/users";
import Delete from "./Delete";
import remittances from "../../requests/remittances";
import { mapGetters } from "vuex";

export default {
  mixins: [usersMixin],
  components: {
    ConfirmDialog,
    Delete,
    RemittanceDetails,
  },
  props: {
    remittanceId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    hideDialog: true,
    remittance: null,
  }),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  created: async function () {},
  methods: {
    deleted: async function () {
      this.hideDialog = true;
      this.$emit("reload");
    },
    shown: async function () {
      this.hideDialog = false;
      this.remittance = await remittances.details(this.remittanceId);
    },
  },
};
</script>
