<template>
  <confirm-dialog :show="dialog" hide-actions @shown="loadService">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small icon class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-img max-width="22px" :src="require(`@/assets/category.svg`)" />
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("profit-margins") }}
    </template>
    <validation-observer v-slot="{ valid }">
      <v-form class="mt-5" @submit.prevent="submit">
        <v-row justify="center">
          <v-col cols="12">
            <v-row>
              <v-col cols="6" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('fee').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.fee"
                    outlined
                    :label="$t('fee')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('profit-margin').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.margin"
                    outlined
                    :label="$t('profit-margin')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col cols="8">
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  elevation="0"
                  block
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import users from "../../requests/users";

const MLC_SERVICE = "mlc-remittance";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
    request: {
      fee: "",
      margin: "",
    },
  }),
  created: async function () {},
  methods: {
    loadService: async function () {
      this.dialog = false;
      const service = await users.serviceBySlug(this.item.id, MLC_SERVICE);
      this.request.fee = service.fee;
      this.request.margin = service.margin;
    },
    submit: async function () {
      try {
        await users.updateServices(
          this.item.id,
          MLC_SERVICE,
          this.request.fee,
          this.request.margin
        );
        this.dialog = true;
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
        this.$emit("success");
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>
