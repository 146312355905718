<template>
  <confirm-dialog v-if="user" hide-actions :show="dialog">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn elevation="0" block class="px-20 py-10" v-bind="attrs" v-on="on">
          <div class="user-icon">
            <v-img max-width="24px" :src="require('@/assets/profile.svg')" />
          </div>
          <div v-if="user" class="user-info">
            <span>{{ $t(`level-.${user.level}`) }}</span>
            <span>{{ user.name }}</span>
          </div>
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("profile") }}
    </template>
    <v-row v-if="!user.clinic">
      <v-col cols="6">
        <attribute>
          <template #name>{{ $t("name") }}</template>
          {{ `${user.name}` }}
        </attribute>
      </v-col>
      <v-col cols="6">
        <attribute>
          <template #name>{{ $t("access-level") }}</template>
          {{ $t(`level-.${user.level}`) }}
        </attribute>
      </v-col>
      <v-col cols="6">
        <attribute>
          <template #name>{{ $t("email") }}</template>
          {{ `${user.email}` }}
        </attribute>
      </v-col>
      <v-col cols="6">
        <attribute v-if="user.phone_number">
          <template #name>{{ $t("phone-number") }}</template>
          {{ `${user.phone_number}` }}
        </attribute>
      </v-col>
      <v-col cols="12">
        <change-password-dialog :item="user" @succeeded="dialog = true">
          <template #activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on">{{ $t("change-password") }}</a>
          </template>
        </change-password-dialog>
      </v-col>
    </v-row>
  </confirm-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ConfirmDialog from "./ConfirmDialog";
import users from "../../requests/users";
import Attribute from "../components/Attribute";
import ChangePasswordDialog from "../components/ChangePasswordDialog";

export default {
  components: {
    Attribute,
    ConfirmDialog,
    ChangePasswordDialog,
  },
  props: {},
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  data: () => ({
    user: null,
    dialog: false,
    request: {
      password: "",
      confirm: "",
    },
  }),
  created: async function () {
    this.user = await this.getUserInfo;
  },
  methods: {
    changePassword: async function () {
      try {
        await users.changeOtherPassword(
          this.item.id,
          this.request.password,
          this.request.confirm
        );
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
        this.discard();
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    discard: async function () {
      this.dialog = true;
      this.request.password = "";
      this.request.confirm = "";
      setTimeout(
        function () {
          this.dialog = false;
        }.bind(this),
        500
      );
    },
  },
};
</script>
