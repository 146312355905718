<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("transfers") }}</h2>
      <p>{{ $t("transfers-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="7">
        <datatable :headers="headers" :table="entries">
          <template #[`item.account`]="{ item }">
            <span>{{ item.account.name }}</span>
            <span>{{ $options.filters.cardNumber(item.account.card_number) }}</span>
          </template>
          <template #[`item.amount`]="{ item }">
            <span>
              {{ `\$${$options.filters.money(item.amount)}` }}
            </span>
            <span>{{ $t(item.account.currency) }}</span>
          </template>
          <template #[`item.destination`]="{ item }">
            <span v-if="item.transfer_account">
              {{ `${item.transfer_account.name}: ${$options.filters.cardNumber(item.transfer_account.card_number)}` }}
            </span>
            <span v-else>
              {{ $options.filters.cardNumber(item.destination) }}
            </span>
            <span class="maxw-200">{{ item.note }}</span>
          </template>
          <template #[`item.date`]="{ item }">
            <span>{{ date(item.date) }}</span>
            <span>{{ date(item.created_at) }}</span>
          </template>
          <template #[`item.status`]="{ item }">
            <confirm-dialog @confirmed="approve(item)">
              <template #activator="{ on, attrs }">
                <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
                  <v-btn small icon color="primary" class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on"
                    :disabled="item.status !== 'pending'">
                    <v-icon>{{
                      item.status === "pending" ? "mdi-clock-outline" : "mdi-check"
                    }}</v-icon>
                  </v-btn>
                </slot>
              </template>
              <template #title>
                {{ $t("deposits-.approve") }}
              </template>
              {{ $t("deposits-.approve_question") }}
            </confirm-dialog>
          </template>
          <template #[`item.id`]="{ item }">
            <div v-if="item.status === 'pending'" class="svg-buttons" style="white-space: nowrap">
              <v-btn small icon color="success" class="pl-0 pr-0" @click.prevent="edit(item)">
                <v-img max-width="24px" :src="require(`@/assets/edit.svg`)" />
              </v-btn>
              <confirm-dialog v-if="isAdmin(getUserInfo.level)" @confirmed="remove(item)">
                <template #activator="{ on, attrs }">
                  <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
                    <v-btn small icon color="error" class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
                      <v-img max-width="22px" :src="require(`@/assets/delete.svg`)" />
                    </v-btn>
                  </slot>
                </template>
                <template #title>
                  {{ $t("transfers-.remove") }}
                </template>
                {{ $t("transfers-.remove_question") }}
              </confirm-dialog>
            </div>
          </template>
        </datatable>
      </v-col>
      <v-col cols="12" md="5">
        <validation-observer v-slot="{ valid }">
          <v-form @submit.prevent="submit">
            <v-row no-gutters>
              <v-col>
                <h2 class="mb-2">{{ $t(`transfers-.${formAction}`) }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('account').toLowerCase()" :rules="{ required: true }">
                  <v-autocomplete v-model="request.account" outlined :items="accounts" :label="$t('account')"
                    :error-messages="errors" item-value="id" item-text="card_number">
                    <template #selection="{ item }">
                      {{ `${item.name} - ${$t(item.currency)}` }}
                    </template>
                    <template #item="{ item }">
                      {{ `${item.name} - ${$t(item.currency)}: ${item.card_number}` }}
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-menu transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider v-slot="{ errors }" :name="$t('date').toLowerCase()" :rules="{ required: true }">
                      <v-text-field v-model="request.date" :label="$t('date')" readonly outlined v-bind="attrs" v-on="on"
                        :error-messages="errors">
                        <template #prepend-inner>
                          <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker v-model="request.date"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('amount').toLowerCase()"
                  :rules="{ required: true, double: true }">
                  <v-text-field v-model="request.amount" outlined :label="$t('amount')" :error-messages="errors" />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('destination').toLowerCase()"
                  :rules="{ required: true }">
                  <v-autocomplete v-model="request.transfer_account" outlined :items="transferAccounts"
                    :label="$t('destination')" :error-messages="errors" item-value="id" item-text="card_number">
                    <template #selection="{ item }">
                      {{ `${item.name} - ${$t(item.currency)}` }}
                    </template>
                    <template #item="{ item }">
                      {{ `${item.name} - ${$t(item.currency)}: ${item.card_number}` }}
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('note').toLowerCase()" :rules="{ required: true }">
                  <v-textarea v-model="request.note" outlined :label="$t('note')" :error-messages="errors" />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col v-if="request.id" cols="6">
                <v-btn block outlined color="error" elevation="0" @click.prevent="resetForm">
                  {{ $t("discard") }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" type="submit" :disabled="!valid" elevation="0">
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import transfers from "../requests/transfers";
import accounts from "../requests/accounts";
import * as dayjs from "dayjs";
import userMixin from "../mixins/users";
import ConfirmDialog from "./components/ConfirmDialog";

export default {
  mixins: [userMixin],
  components: {
    Heading,
    Datatable,
    ConfirmDialog,
  },
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
    formAction: function () {
      return this.request.id === "" ? "new" : "edit";
    },
    headers: function () {
      return [
        {
          text: this.$t("account"),
          value: "account",
          sortable: false,
        },
        {
          text: this.$t("destination"),
          value: "destination",
          sortable: false,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          sortable: false,
        },
        {
          text: this.$t("date"),
          value: "date",
          sortable: false,
        },
        {
          text: "",
          value: "status",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    accounts: [],
    transferAccounts: [],
    request: {
      id: "",
      account: "",
      transfer_account: "",
      date: "",
      amount: "",
      note: "",
    },
  }),
  watch: {},
  created: async function () {
    this.accounts = await accounts.listDepositAllowed();
    if (this.accounts.length > 0) {
      this.request.account = this.accounts[0].id;
    }
    this.transferAccounts = await accounts.listTransferAllowed();
    if (this.transferAccounts.length > 0) {
      this.request.transfer_account = this.transferAccounts[0].id;
    }
    await this.loadEntries();
  },
  methods: {
    date: function (date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    loadEntries: async function () {
      const response = await transfers.load();
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
    },
    submit: async function () {
      try {
        this.request.id !== ""
          ? await transfers.update(this.request.id, this.request)
          : await transfers.create(this.request);

        await this.resetForm();
        await this.loadEntries();
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    resetForm: async function () {
      this.request = {
        id: "",
        account: "",
        date: "",
        amount: "",
        transfer_account: "",
        note: "",
      };
    },
    edit: async function (item) {
      const clone = Object.assign({}, item);
      this.request = {
        id: clone.id,
        account: clone.account.id,
        date: clone.date,
        amount: clone.amount,
        transfer_account: clone.transfer_account ? clone.transfer_account.id : "",
        note: clone.note,
      };
    },
    remove: async function (item) {
      if (await transfers.remove(item.id)) {
        await this.loadEntries();
      }
    },
    approve: async function (item) {
      try {
        if (await transfers.approve(item.id)) {
          await this.loadEntries();
        }
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.maxw-200 {
  max-width: 250px;
}
</style>
