import api from '@/plugins/api';

export const services = {
  load: async function (filters) {
    try {
      const response = await api.get('/profits', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default services
