<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("businesses") }}</h2>
      <p>{{ $t("businesses-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="7">
        <datatable :headers="headers" :table="entries">
          <template #[`item.name`]="{ item }">
            <span :class="item.active ? '' : 'strickeout'">{{ item.name }}</span>
          </template>
          <template #[`item.id`]="{ item }">
            <div class="svg-buttons" style="white-space: nowrap">
              <v-btn
                small
                icon
                color="success"
                class="pl-0 pr-0"
                @click.prevent="edit(item)"
              >
                <v-img max-width="24px" :src="require(`@/assets/edit.svg`)" />
              </v-btn>
              <confirm-dialog @confirmed="remove(item)">
                <template #activator="{ on, attrs }">
                  <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
                    <v-btn
                      small
                      icon
                      color="error"
                      class="ml-2 pl-0 pr-0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-img max-width="22px" :src="require(`@/assets/delete.svg`)" />
                    </v-btn>
                  </slot>
                </template>
                <template #title>
                  {{ $t("businesses-.remove") }}
                </template>
                {{ $t("businesses-.remove_question") }}
              </confirm-dialog>
            </div>
          </template>
        </datatable>
      </v-col>
      <v-col cols="12" md="5">
        <validation-observer ref="validator" v-slot="{ valid }">
          <v-form @submit.prevent="submit">
            <v-row no-gutters>
              <v-col>
                <h2 class="mb-2">{{ $t(`businesses-.${formAction}`) }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('name').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.name"
                    outlined
                    :label="$t('name')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox v-model="request.active" :label="$t('active')" class="mt-0" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col v-if="request.id" cols="6">
                <v-btn
                  block
                  outlined
                  color="error"
                  elevation="0"
                  @click.prevent="resetForm"
                >
                  {{ $t("discard") }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  elevation="0"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import businesses from "../requests/businesses";
import ConfirmDialog from "./components/ConfirmDialog";

export default {
  mixins: [],
  components: {
    Heading,
    Datatable,
    ConfirmDialog,
  },
  computed: {
    formAction: function () {
      return this.request.id === "" ? "new" : "edit";
    },
    headers: function () {
      return [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    requestBase: {
      id: "",
      name: "",
      active: false,
    },
    request: null,
  }),
  watch: {},
  created: async function () {
    this.resetForm();
    this.loadEntries();
  },
  methods: {
    loadEntries: async function () {
      const response = await businesses.load();
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
    },
    submit: async function () {
      try {
        this.request.id !== ""
          ? await businesses.update(this.request.id, this.request)
          : await businesses.create(this.request);

        this.resetForm();
        this.loadEntries();
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    resetForm: async function () {
      this.request = Object.assign({}, this.requestBase);
      if (this.$refs.validator) this.$refs.validator.reset();
    },
    edit: async function (item) {
      this.request = Object.assign({}, item);
    },
    remove: async function (item) {
      if (await businesses.remove(item.id)) {
        this.loadEntries();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
