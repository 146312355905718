<template>
  <v-container fluid class="py-0">
    <heading />
    <v-row class="mt-5">
      <v-col>
        <v-chip
          outlined
          :color="`${active == 'all' ? 'primary' : ''}`"
          class="text-lowercase cursor-pointer"
          @click="active = 'all'"
        >
          {{ $t("all") }}
        </v-chip>
        <v-chip
          outlined
          :color="`${active == 'last-week' ? 'primary' : ''}`"
          class="ml-1 text-lowercase cursor-pointer"
          @click="active = 'last-week'"
        >
          {{ $t("past-week") }}
        </v-chip>
        <v-chip
          outlined
          :color="`${active == 'this-month' ? 'primary' : ''}`"
          class="ml-1 text-lowercase cursor-pointer"
          @click="active = 'this-month'"
        >
          {{ $t("this-month") }}
        </v-chip>
        <v-chip
          outlined
          :color="`${active == 'last-month' ? 'primary' : ''}`"
          class="ml-1 text-lowercase cursor-pointer"
          @click="active = 'last-month'"
        >
          {{ $t("last-month") }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" class="big-chips">
        <big-chip
          type="success"
          :number="counters.completed"
          :description="$t('completed-sales')"
        />
        <big-chip
          type="warning"
          :number="counters.pending"
          :description="$t('pending-sales')"
        />
        <big-chip
          type="danger"
          :number="counters.profits"
          money
          :description="$t('profits')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as dayjs from "dayjs";
import reports from "../requests/reports";
import BigChip from "./components/BigChip";
import Heading from "./components/Heading";

export default {
  components: {
    Heading,
    BigChip,
  },
  props: {},
  mixins: [],
  computed: {},
  data: () => ({
    counters: {
      completed: null,
      pending: null,
      profits: null,
    },
    active: "",
  }),
  async created() {
    this.active = "all";
  },
  watch: {
    active: async function (value) {
      const filters = {};
      if (value == "this-month") {
        filters.from = dayjs().startOf("month").format("YYYY-MM-DDTHH:mm:ssZ");
        filters.to = dayjs().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ");
      }
      else if (value == "last-month") {
        const pivot = dayjs().subtract(1, 'month')
        filters.from = pivot.startOf("month").format("YYYY-MM-DDTHH:mm:ssZ");
        filters.to = pivot.endOf("month").format("YYYY-MM-DDTHH:mm:ssZ");
      }
      else if (value == "last-week") {
        filters.from = dayjs().startOf("week").format("YYYY-MM-DDTHH:mm:ssZ");
        filters.to = dayjs().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ");
      }
      this.counters = await reports.sales.resume(filters);
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  .big-chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    > div {
      flex: 1;
      min-width: 200px;
      width: 30%;
    }
  }
}
</style>
