<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="mt-2">
          <v-text-field
            dense
            hide-details
            v-model="search"
            outlined
            :label="$t('search')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-list class="v-list-search-result">
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in users" :key="i">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text--primary">{{ $t(`level-.${item.level}`) }}:</span>
                    {{ item.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import users from "../../requests/users";

export default {
  mixins: [],
  props: {},
  components: {},
  data: () => ({
    users: null,
    user: null,
    timeout: null,
    search: "",
    selectedItem: null,
  }),
  computed: {},
  watch: {
    search: async function () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        function () {
          this.loadItems();
        }.bind(this),
        1000
      );
    },
    selectedItem: async function (index) {
      this.$emit("selected", this.users[index]);
    },
  },
  created: async function () {
    await this.loadItems();
    for (const i in this.users) {
      if (this.users[i].id == this.$route.params.id) {
        this.selectedItem = i;
        break;
      }
    }
  },
  methods: {
    loadItems: async function () {
      const params = {
        level: ["admin", "accountant", "manager", "provider", "reseller"],
      };
      params.keyword = this.search;
      this.users = (await users.load(params)).items;
    },
  },
};
</script>

<style lang="scss" scoped></style>
