<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t(title) }}</h2>
      <p>{{ $t("cards-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="7">
        <datatable :headers="headers" :table="entries">
          <template #[`item.card_number`]="{ item }">
            <span>{{ item.name }}</span>
            <span>
              {{ `${$t(item.currency)}: ${item.card_number}` }}
            </span>
            <span v-if="item.account_number">
              {{ item.account_number }}
            </span>
          </template>
          <template #[`item.balance_real`]="{ item }">
            <span>{{ `\$${$options.filters.money(item.balance_real)}` }}</span>
            <!-- <span>
              {{ `\$${$options.filters.money(item.balance)}` }}
            </span> -->
          </template>
          <template #[`item.id`]="{ item }">
            <div class="svg-buttons" style="white-space: nowrap">
              <v-btn
                small
                icon
                color="success"
                class="pl-0 pr-0"
                @click.prevent="edit(item)"
              >
                <v-img max-width="24px" :src="require(`@/assets/edit.svg`)" />
              </v-btn>
              <v-btn
                v-if="isAdmin(getUserInfo.level)"
                small
                icon
                color="error"
                class="ml-2 pl-0 pr-0"
                @click.prevent="remove(item)"
              >
                <v-img max-width="24px" :src="require(`@/assets/delete.svg`)" />
              </v-btn>
            </div>
          </template>
          <template v-if="isDepositable" #[`body.append`]>
            <tr v-for="entry in totals" :key="entry.currency">
              <td colspan="4">
                {{
                  `Total ${$t(entry.currency)}: \$${$options.filters.money(entry.total)}`
                }}
              </td>
            </tr>
          </template>
        </datatable>
      </v-col>
      <v-col cols="12" md="5">
        <validation-observer v-if="request" ref="observer" v-slot="{ valid }">
          <v-form @submit.prevent="submit">
            <v-row no-gutters>
              <v-col>
                <h2 class="mb-2">{{ $t(`cards-.${formAction}`) }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('name').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.name"
                    outlined
                    :label="$t('name')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('card-number').toLowerCase()"
                  :rules="{ required: true, length: 16 }"
                >
                  <v-text-field
                    v-model="request.card_number"
                    outlined
                    :label="$t('card-number')"
                    :error-messages="errors"
                    :disabled="request.movements > 0"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-number').toLowerCase()"
                  :rules="{ length: 16 }"
                >
                  <v-text-field
                    v-model="request.account_number"
                    outlined
                    :label="$t('account-number')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row v-if="isDepositable" no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('balance').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.balance"
                    outlined
                    :label="$t('balance')"
                    :error-messages="errors"
                    :disabled="request.movements > 0"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('currency').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="request.currency"
                    outlined
                    :items="['mlc']"
                    :label="$t('currency')"
                    :error-messages="errors"
                    :disabled="request.movements > 0"
                  >
                    <template #selection="{ item }">
                      {{ $t(item) }}
                    </template>
                    <template #item="{ item }">
                      {{ $t(item) }}
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col v-if="request?.id" cols="6">
                <v-btn
                  block
                  outlined
                  color="error"
                  elevation="0"
                  @click.prevent="resetForm"
                >
                  {{ $t("discard") }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  elevation="0"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import accounts from "../requests/accounts";
import userMixin from "../mixins/users";

export default {
  props: {},
  mixins: [userMixin],
  components: {
    Heading,
    Datatable,
  },
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
    isDepositable: function () {
      return this.capability === "deposit";
    },
    formAction: function () {
      return this.request && this.request?.id ? "edit" : "new";
    },
    headers: function () {
      const headers = [
        {
          text: this.$t("card-number"),
          value: "card_number",
          sortable: false,
        },
      ];
      if (this.isDepositable) {
        headers.push({
          text: this.$t("balance"),
          value: "balance_real",
          sortable: false,
        });
        headers.push({
          text: this.$t("rate"),
          value: "rate",
          sortable: false,
        });
      }
      headers.push({ text: "", value: "id", sortable: false, width: "20px" });
      return headers;
    },
  },
  data: () => ({
    title: null,
    capability: null,
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    baseRequest: {
      id: "",
      name: "",
      card_number: "",
      account_number: "",
      balance: "",
      currency: "mlc",
      movements: 0,
    },
    totals: [],
    request: null,
  }),
  watch: {
    capability: function () {
      this.resetForm();
      this.loadEntries();
    },
  },
  beforeRouteLeave: function (to, from, next) {
    if (to.meta?.capability ?? false) this.capability = to.meta.capability;
    if (to.meta?.capability ?? false) this.title = to.meta.title;
    next();
  },
  created: async function () {
    this.capability = this.$route.meta.capability;
    this.title = this.$route.meta.title;
  },
  methods: {
    loadEntries: async function () {
      const response = await accounts.load({ capability: this.capability });
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
      this.totals = response.totals;
    },
    submit: async function () {
      const request = Object.assign({}, this.request);
      request.allow_transfers = true;
      request.allow_deposits = this.capability === "deposit";

      const response = this.request.id
        ? await accounts.update(this.request.id, request)
        : await accounts.create(request);
      if (response) {
        await this.resetForm();
        await this.loadEntries();
      }
    },
    resetForm: async function () {
      this.request = Object({}, this.requestBase);
      if (this.$refs.observer) this.$refs.observer.reset();
    },
    edit: async function (item) {
      this.request = Object.assign({}, item);
      this.request.balance = item.balance_real;
    },
    remove: async function (item) {
      if (await accounts.remove(item.id)) {
        await this.loadEntries();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
