import api from '@/plugins/api';

export const remittances = {
  load: async function (filters) {
    try {
      const response = await api.get('/remittances', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  details: async function (id) {
    try {
      const response = await api.get(`/remittances/${id}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function (data) {
    try {
      const response = await api.post('/remittances', data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  process: async function (id, data) {
    try {
      const response = await api.put(`/remittances/${id}/process`, data)
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  reasign: async function (id, data) {
    try {
      const response = await api.put(`/remittances/${id}/reasign`, data)
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  delete: async function (id, data) {
    try {
      const response = await api.delete(`/remittances/${id}`, data)
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default remittances
