var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('heading',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span')]},proxy:true}])},[_c('h2',[_vm._v(_vm._s(_vm.$t("remittances")))]),_c('p',[_vm._v(_vm._s(_vm.$t("remittances-.sub-heading")))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"7","xl":"9"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('datatable',{attrs:{"headers":_vm.headers,"table":_vm.entries},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('filters-modal',{on:{"filtered":_vm.loadEntries}})]},proxy:true},{key:`item.paid_amount`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(`${_vm.$t(item.sale.payment_method)}: ${ item.sale.payment_sender ? item.sale.payment_sender : item.sale.payment_code }`))]),_c('span',{class:item.sale.status === 'cancelled' ? 'strickeout' : ''},[_vm._v(" "+_vm._s(`${_vm.$t(item.sale.paid_currency)}: \$${_vm.$options.filters.money( item.sale.paid_amount )}`)+" ")])]}},{key:`item.received_amount`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("cardNumber")(item.account_number)))]),_c('span',{class:item.sale.status === 'cancelled' ? 'strickeout' : ''},[_vm._v(" "+_vm._s(`${_vm.$t(item.received_currency)}: \$${_vm.$options.filters.money( item.received_amount )}`)+" ")]),_c('span',[_vm._v(_vm._s(item.note))])]}},{key:`item.created_at`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.date(item.created_at)))]),(!_vm.isBusinessDependant(_vm.getUserInfo.level) && item.sale.reseller)?_c('span',[_vm._v(" "+_vm._s(item.sale.reseller.name)+" ")]):_vm._e()]}},{key:`item.status`,fn:function({ item }){return [_c('status-icon',{attrs:{"status":item.sale.status}})]}},{key:`item.id`,fn:function({ item }){return [_c('div',{staticClass:"svg-buttons",staticStyle:{"white-space":"nowrap"}},[_c('remittance-details',{attrs:{"remittance-id":item.id},on:{"reload":_vm.loadEntries}}),(
                    !_vm.isBusinessDependant(_vm.getUserInfo.level) &&
                    item.sale.status === 'pending'
                  )?_c('process',{attrs:{"item":item},on:{"processed":_vm.loadEntries}}):_vm._e(),(
                    !_vm.isBusinessDependant(_vm.getUserInfo.level) &&
                    item.sale.status === 'pending'
                  )?_c('cancel',{attrs:{"item":item.sale},on:{"cancelled":_vm.loadEntries}}):_vm._e(),(
                    !_vm.isBusinessDependant(_vm.getUserInfo.level) &&
                    item.sale.status === 'confirmed' &&
                    !item.sale.reseller
                  )?_c('reasign',{attrs:{"item":item},on:{"reasigned":_vm.loadEntries}}):_vm._e()],1)]}}],null,true)})],1)],1)],1),_c('v-col',[_c('remittance-form',{on:{"success":_vm.loadEntries}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }