import api from '@/plugins/api';

export const users = {
  load: async function (filters) {
    try {
      const response = await api.get('/accounts', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  list: async function (filters) {
    try {
      const response = await api.get('/accounts/list', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  listTransferAllowed: async function () {
    return this.list({ 'allow_transfers': 1 });
  },
  listDepositAllowed: async function () {
    return this.list({ 'allow_deposits': 1 });
  },
  details: async function (id) {
    try {
      const response = await api.get(`/accounts/${id}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function (data) {
    try {
      const response = await api.post('/accounts', data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  update: async function (id, data) {
    try {
      const response = await api.patch(`/accounts/${id}`, data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  remove: async function (id) {
    try {
      await api.delete(`/accounts/${id}`)
      return true
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default users
