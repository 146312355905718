<template>
  <v-row v-if="remittance" class="mt-0">
    <v-col v-if="remittance.sale.reseller" cols="12">
      <attribute>
        <template #name>{{ $t("reseller") }}</template>
        {{ `${remittance.sale.reseller.name} - ${remittance.sale.reseller.email}` }}
      </attribute>
    </v-col>
    <v-col v-if="remittance.account_number" cols="12">
      <attribute>
        <template #name>{{ $t("account-number") }}</template>
        {{ remittance.account_number | cardNumber }}
      </attribute>
    </v-col>
    <v-col cols="4">
      <attribute>
        <template #name>{{ $t("date") }}</template>
        {{ date(remittance.sale.created_at) }}
      </attribute>
    </v-col>
    <v-col cols="4">
      <attribute>
        <template #name>{{ $t("receives") }}</template>
        {{
          `\$${$options.filters.money(remittance.received_amount)} ${$t(
            remittance.received_currency
          )} (${remittance.margin})`
        }}
      </attribute>
    </v-col>
    <v-col cols="4">
      <attribute>
        <template #name>{{ $t("status") }}</template>
        {{ $t(remittance.sale.status) }}
      </attribute>
    </v-col>
    <v-col cols="6">
      <attribute>
        <template #name>{{ $t("payment-method") }}</template>
        {{
          `${$t(remittance.sale.payment_method)}: \$${$options.filters.money(
            remittance.sale.paid_amount
          )} ${$t(remittance.sale.paid_currency)}`
        }}
      </attribute>
    </v-col>
    <v-col cols="6">
      <attribute>
        <template #name>{{ $t("sender") }}</template>
        {{ remittance.sale.payment_sender }}
      </attribute>
    </v-col>
    <v-col v-for="profit of remittance.sale.profits" :key="profit.id" cols="6">
      <attribute>
        <template #name>{{
          `${$t("profit")}: ${profit.user ? profit.user.name : $t("company")}`
        }}</template>
        {{ `\$${$options.filters.money(profit.amount)}` }}
      </attribute>
    </v-col>
    <v-col v-if="remittance.account && !isBusinessDependant(getUserInfo.level)" cols="12">
      <attribute>
        <template #name>{{ $t("account") }}</template>
        {{
          `${remittance.account.name}: ${$options.filters.cardNumber(
            remittance.account.card_number
          )}`
        }}
      </attribute>
    </v-col>
  </v-row>
</template>

<script>
import Attribute from "../components/Attribute";
import usersMixin from "../../mixins/users";
import * as dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  mixins: [usersMixin],
  components: {
    Attribute,
  },
  props: {
    remittance: {
      type: Object,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  created: async function () {},
  methods: {
    date: function (date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
  },
};
</script>
