import apiRequester from '@/plugins/api'
import * as dayjs from 'dayjs'
import Vue from 'vue'
import store from '../store'

// initial state
const state = () => ({
  authToken: Vue.$cookies.get('authToken') || null,
  authTokenExpiresAt: null,
  tokenPayload: {},
  authenticating: false,
})

// getters
const getters = {
  authToken: state => state.authToken,

  isLoggedIn: state => !!state.authToken,

  hasValidAuth: (state) => {
    if (!state.authToken) {
      return false
    }

    const tokenExpiration = dayjs(state.payload.exp)
    return tokenExpiration.isBefore(dayjs())
  },

  isAuthenticating: (state) => {
    return state.authenticating
  },

  /**
   * @returns {dayjs} Date object indicating token expiration
   */
  authTokenExpiration: (state) => {
    return state.authTokenExpiresAt || dayjs()
  },

  tokenPayload: (state) => {
    return state.tokenPayload
  },
}

// mutations
const mutations = {
  /**
   * @param {Object} state
   * @param {Boolean} status
   */
  setAuthStatus: (state, status) => {
    state.authenticating = status
  },

  setAuthToken: (state, token) => {
    state.authToken = token
  },

  setAuthTokenExpiration: (state, secondsToExpire) => {
    state.authTokenExpiresAt = dayjs().add(secondsToExpire, 'second')
  },

  setTokenPayload: (state, payload) => {
    state.tokenPayload = payload
  },
}

// actions
const actions = {
  setAuthState: ({ commit }, loading) => {
    commit('setAuthStatus', loading)
  },

  setAuthCookie: (state, token) => {
    Vue.$cookies.config('1d', '/', '', true)
    Vue.$cookies.set('authToken', token, 0)
  },

  deleteAuthCookies: () => {
    state.authToken = null
    Vue.$cookies.remove('authToken')
  },

  processAuthResponse: ({ commit, dispatch }, authPayload) => {
    // commit the new received auth token
    commit('setAuthToken', authPayload.access_token)
    // set the expiration date
    commit('setAuthTokenExpiration', authPayload.expires_in)
    // dispatch action to save cookie in browser
    dispatch('setAuthCookie', authPayload.access_token)
  },

  login: async ({ commit, dispatch }, payload) => {
    // change auth status
    commit('setAuthStatus', true)

    // send request to API
    try {
      const url = `${process.env.VUE_APP_API_ROOT}/auth/login`
      const response = await apiRequester.post(url, payload)

      if (response.data.access_token) {
        store.dispatch('user/setUserInfo', response.data)
        dispatch('processAuthResponse', response.data)
      }
      return Promise.resolve(response)
    } catch (error) {
      commit('setAuthStatus', false)
      return Promise.reject(error.response)
    }
  },

  login_as: async ({ commit, dispatch }, payload) => {
    // change auth status
    commit('setAuthStatus', true)

    // send request to API
    try {
      const response = await apiRequester.post(
        `${process.env.VUE_APP_API_ROOT}/auth/login_as`,
        payload,
        { cache: false },
      )

      if (response.data.access_token) {
        store.dispatch('user/setUserInfo', response.data)
        dispatch('processAuthResponse', response.data)
      }

      return Promise.resolve(response)
    } catch (error) {
      commit('setAuthStatus', false)
      return Promise.reject(error.response)
    }
  },

  refresh: async ({ dispatch }) => {
    try {
      const response = await apiRequester.post('/auth/refresh')

      if (response.status === 200 && response.data.access_token) {
        dispatch('processAuthResponse', response.data)
      }

      return Promise.resolve(response)
    } catch (error) {
      console.error('Error on refresh token action', error)
      return Promise.reject(error.response)
    }
  },

  setAuthTokenExpiration: ({ commit }, datetime) => {
    commit('setAuthTokenExpiration', datetime)
  },

  setTokenPayload: ({ commit }, jwt) => {
    const parts = jwt.split('.')
    const payload = JSON.parse(window.atob(parts[1]))

    commit('setTokenPayload', payload)
  },

  destroySession: async ({ dispatch }) => {
    try {
      await apiRequester.post('/auth/logout', {}, { cache: false })
    } catch (e) {
      console.error(e)
    }
    await dispatch('deleteAuthCookies')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}